  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/2-3-1-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li><a href='/expertise-assure/garanties/'>Garanties</a></li><li>MRH</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’assurance Multirisque habitation (MRH)</h1>

<p>Votre contrat d’habitation - multirisques habitation - est un format de contrat d’assurances souscrit par la majorité des particuliers et entreprises.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="MRH" />
</div>

<p>Il ouvre accès, en cas de sinistre, à des garanties décrites dans les conditions générales et précisées dans les conditions particulières, notamment en matière de sinistres consécutifs à une catastrophe naturelle (inondation, sécheresse…).</p>


<h2>Les garanties de votre contrat multirisque habitation </h2>

<p>Les conditions générales et particulières de votre contrat multirisque habitation vous ouvrent un champ de garanties possibles lorsque vous êtes sinistrés.</p>

<p>Mais certaines conditions peuvent paraître flous et mal interprétées.</p>
<p>Cette opacité peut être ainsi utilisée contre vos intérêts afin de réduire vos indemnités.</p>

<p>Dans une telle configuration, l’assistance d’un Expert d’assuré s’avère particulièrement utile afin de démêler le vrai du faux et rappeler vos garanties qui doivent être acquises selon vos modalités contractuelles. </p>

<p>Un expert du cabinet RV Expertises vous aide à mieux connaître vos garanties et ainsi mieux vous défendre pour une juste indemnisation de votre sinistre.</p>

<p>Si vous estimez que les garanties inhérentes dans votre contrat MRH spécifiées par les conditions particulières, ne sont pas respectées, n’hésitez pas à contacter notre Expert d’assuré.</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert d’assuré</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/garanties/mrh/' className='active'>MRH</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details